import { Box, Theme, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { CheckIcon } from "components/Icons/CheckIcon";
import { StatusOption, StatusTag } from "components/StatusTag/StatusTag";
import { ContractBinding, ContractBindingStatus } from "generated/graphql";
import { getUserName, getUserNameOrEmail } from "helpers/miscelaneous";
import {
  statusComparatorFunction,
  useDateCreatedColumnConfig,
} from "helpers/dataGrid.helpers";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

export const useColumns = (
  theme: Theme,
  contractBindingStatusOptions: StatusOption<ContractBindingStatus>[],
  onStatusChange: (
    row: ContractBinding,
    newStatus: ContractBindingStatus
  ) => void
): GridColDef<ContractBinding>[] => {
  const { t } = useTranslation();
  const dateCreatedColConfig = useDateCreatedColumnConfig();
  const columns = useMemo(
    () =>
      [
        {
          field: "companyName",
          headerName: t("common.labels.company"),
          flex: 0.125,
          minWidth: 190,
          resizable: true,
          valueGetter: (_, row) => {
            return row.company.tradingName;
          },
        },
        {
          field: "contractBindingTypeName",
          headerName: t("common.labels.bindingType"),
          flex: 0.15,
          minWidth: 90,
          resizable: true,
          renderCell: (
            params: GridRenderCellParams<any, ContractBinding, any>
          ) => {
            return (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                width="100%"
                height="100%"
                minWidth="fit-content"
              >
                {params.row.defaultCompany ? (
                  <CheckIcon color={theme.palette.common.black} />
                ) : (
                  <Box width={20}></Box>
                )}
                <Typography ml={1}>
                  {params.row.contractBindingType.description}
                </Typography>
              </Box>
            );
          },
        },
        {
          field: "status",
          headerName: t("common.labels.status"),
          flex: 0.125,
          minWidth: 90,
          resizable: true,
          sortComparator: statusComparatorFunction,
          renderCell: (
            params: GridRenderCellParams<any, ContractBinding, any>
          ) => {
            return (
              <StatusTag
                status={params.row.status}
                options={contractBindingStatusOptions}
                onChange={(newStatus: StatusOption<ContractBindingStatus>) =>
                  onStatusChange(params.row, newStatus.id)
                }
              />
            );
          },
        },
        {
          field: "representativeName",
          headerName: t("AdminConsole.Bindings.labels.representative"),
          flex: 0.15,
          minWidth: 90,
          resizable: true,
          valueGetter: (_, row) => getUserNameOrEmail(row.representative),
        },
        {
          field: "isCompanyBilled",
          headerName: t("AdminConsole.Bindings.labels.billedTo"),
          flex: 0.15,
          minWidth: 90,
          resizable: true,
          renderCell: (
            params: GridRenderCellParams<any, ContractBinding, any>
          ) => {
            return (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
                minWidth="fit-content"
              >
                {params.row.isCompanyBilled && (
                  <CheckIcon color={theme.palette.common.black} />
                )}
              </Box>
            );
          },
        },
        dateCreatedColConfig,
        {
          field: "creator",
          headerName: t("common.labels.createdBy"),
          flex: 0.15,
          minWidth: 90,
          resizable: true,
          valueGetter: (_, row) => getUserName(row.creator),
        },
      ] as GridColDef<ContractBinding>[],
    [
      t,
      dateCreatedColConfig,
      theme,
      contractBindingStatusOptions,
      onStatusChange,
    ]
  );
  return columns;
};
