import { Box } from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { ItemStatusOption, ProductType } from "generated/graphql";
import {
  useMemo,
  useState,
  useRef,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import { TabWithCount } from "../TabsWithCount/TabsWithCount";
import { ProductItemsTable } from "./components/ProductItemsTable";
import { StyledProductItemsHeader } from "./ProductItemsView.styled";
import { DraftProductItemsTable } from "./components/DraftProductItemsTable";
import { ProductItemsTablePublicAPI } from "./ProductItemsView.decl";
import { ProductItem } from "containers/Projects/Projects.decl";
import { useHasAccess } from "hooks/useHasAccess";
import { PermissionEnum } from "helpers/Permissions/Permissions.constants";
import { useLocation, useSearchParams } from "react-router-dom";
import { CommonTabs } from "../../../../constants";
import { useContractLite } from "hooks/useContractLite";
import { ExplorerContext } from "../Explorer/Explorer.context";
import { useVariationProductInstance } from "containers/Projects/hooks/useVariationProductInstance";
import { useProductInstanceSchema } from "hooks/useProductInstanceSchema";

export type ProductItemsViewProps<T extends ProductItem> = {
  productItems: T[];
  draftProductItems: T[];
  statusOptions: ItemStatusOption[];
  productType?: ProductType;
  loading: boolean;
  onAddNewProductItem: () => void;
  onDeleteDraft: (draftId: string) => void;
  onNavigateToProductItemDraft: (draftId: string) => void;
};

export const ProductItemsView = <T extends ProductItem>({
  productItems,
  draftProductItems,
  statusOptions,
  productType,
  loading,
  onAddNewProductItem,
  onDeleteDraft,
  onNavigateToProductItemDraft,
}: ProductItemsViewProps<T>) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const locationState = location.state as
    | { autoSelectTab?: string }
    | undefined;
  const { contractId } = useContext(ExplorerContext);
  const productInstanceId = searchParams.get("productInstanceId");
  const productItemsTableRef = useRef<ProductItemsTablePublicAPI>(null);
  const draftProductItemsTableRef = useRef<ProductItemsTablePublicAPI>(null);
  const [productItemsTableSelectionModel, setProductItemsTableSelectionModel] =
    useState<GridRowSelectionModel>();
  const [
    draftProductItemsTableSelectionModel,
    setDraftProductItemsTableSelectionModel,
  ] = useState<GridRowSelectionModel>();
  const { contractLite } = useContractLite(contractId!);
  const sortedStatusOptions = useMemo(() => {
    const sortedOptions = statusOptions.slice();
    sortedOptions.sort((op1, op2) => op1.displayOrder - op2.displayOrder);
    return sortedOptions;
  }, [statusOptions]);
  const defaultStatusOption = sortedStatusOptions.find(
    (option) => option.displayOrder === 1
  );

  const { productInstanceData, loading: productInstanceDataLoading } =
    useProductInstanceSchema(productInstanceId!);

  const schemaContainsSeveritySection = useMemo(() => {
    return !!productInstanceData?.productSchema.schemaSections.items.find(
      (section) => section.name.toLowerCase() === "severity"
    );
  }, [productInstanceData]);

  const [selectedTabId, setSelectedTabId] = useState<string>(
    locationState?.autoSelectTab ?? defaultStatusOption?.id ?? CommonTabs.All
  );

  const [localProductItems, setLocalProductItems] = useState<T[]>(
    productItems || []
  );

  const { variationProductInstanceRole, loading: variationRoleLoading } =
    useVariationProductInstance(contractLite?.id);

  const canCreateProductItemBase = useHasAccess(
    undefined,
    [PermissionEnum.Add],
    productInstanceId!
  );

  const canCreateProductItem = useMemo(() => {
    if (productType === ProductType.Instructions) {
      if (variationRoleLoading) {
        return false;
      } else if (variationProductInstanceRole) {
        return (
          canCreateProductItemBase &&
          variationProductInstanceRole.productRole.name === "Creator"
        );
      }
    }
    return canCreateProductItemBase;
  }, [
    productType,
    variationRoleLoading,
    variationProductInstanceRole,
    canCreateProductItemBase,
  ]);

  const tabs = useMemo(() => {
    const localTabs: TabWithCount[] = sortedStatusOptions.length
      ? [
          {
            id: CommonTabs.All,
            label: t("common.labels.all"),
            count: productItems.length,
          },
        ]
      : [];

    sortedStatusOptions.forEach((statusOption) => {
      localTabs.push({
        id: statusOption.id,
        label: statusOption.description,
        count:
          productItems.filter(
            (productItem) => productItem.statusOptionId === statusOption.id
          ).length || 0,
      });
    });

    if (sortedStatusOptions.length && canCreateProductItem) {
      localTabs.push({
        id: CommonTabs.Drafts,
        label: t("common.labels.yourDrafts"),
        count: draftProductItems.length,
      });
    }

    return localTabs;
  }, [
    sortedStatusOptions,
    productItems,
    draftProductItems,
    canCreateProductItem,
    t,
  ]);

  const handleExportToExcel = () => {
    if (selectedTabId === CommonTabs.Drafts) {
      draftProductItemsTableRef.current?.exportToExcel();
    } else {
      productItemsTableRef.current?.exportToExcel();
    }
  };

  const handleClearSelection = () => {
    if (selectedTabId === CommonTabs.Drafts) {
      draftProductItemsTableRef.current?.clearSelection();
    } else {
      productItemsTableRef.current?.clearSelection();
    }
  };

  const handleSelectedTabChange = useCallback(
    (newSelectedTabId: string) => {
      if (newSelectedTabId === CommonTabs.All) {
        setLocalProductItems(productItems);
      } else {
        setLocalProductItems(
          productItems.filter(
            (productItem) => productItem.statusOptionId === newSelectedTabId
          )
        );
      }

      setSelectedTabId(newSelectedTabId);
    },
    [productItems]
  );

  useEffect(() => {
    handleSelectedTabChange(selectedTabId);
  }, [handleSelectedTabChange, selectedTabId]);

  const computedLoading = loading || productInstanceDataLoading;

  return (
    <Box height="100%" width="100%" display="flex" flexDirection="column">
      <StyledProductItemsHeader
        tabs={tabs}
        selectedTabId={selectedTabId}
        selectedItemsCount={productItemsTableSelectionModel?.length ?? 0}
        onAddProductItem={
          canCreateProductItem ? onAddNewProductItem : undefined
        }
        onSelectedTabChange={handleSelectedTabChange}
        onExportToExcel={handleExportToExcel}
        onClearSelection={handleClearSelection}
        productType={productType}
        floatLastTabToRight={canCreateProductItem === true}
      />
      <Box sx={{ flex: 1, width: "100%" }} mt={3}>
        {selectedTabId === CommonTabs.Drafts && productType ? (
          <DraftProductItemsTable
            selectionModel={draftProductItemsTableSelectionModel}
            draftProductItems={draftProductItems}
            loading={computedLoading}
            apiRef={draftProductItemsTableRef}
            schemaContainsSeveritySection={schemaContainsSeveritySection}
            onSelectionModelChange={setDraftProductItemsTableSelectionModel}
            onDeleteDraft={onDeleteDraft}
            onNavigateToDraftProductItem={onNavigateToProductItemDraft}
          />
        ) : (
          <ProductItemsTable
            statusOptions={sortedStatusOptions}
            selectionModel={productItemsTableSelectionModel}
            productItems={localProductItems}
            loading={computedLoading}
            apiRef={productItemsTableRef}
            productType={productType}
            schemaContainsSeveritySection={schemaContainsSeveritySection}
            onSelectionModelChange={setProductItemsTableSelectionModel}
          />
        )}
      </Box>
    </Box>
  );
};
