import { Box } from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { VariationItem, VariationStatus } from "generated/graphql";
import { useMemo, useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TabWithCount } from "../../TabsWithCount/TabsWithCount";
import { CommonTabs } from "../../../constants";
import { StyledProductItemsHeader } from "../../ProductItemsView/ProductItemsView.styled";
import { ProductItemsTablePublicAPI } from "../../ProductItemsView/ProductItemsView.decl";
import { VariationsTable } from "./components/VariationsTable";

export type VariationsViewProps = {
  variations: VariationItem[];
  loading: boolean;
};

export const VariationsView: React.FC<VariationsViewProps> = ({
  variations,
  loading,
}) => {
  const { t } = useTranslation();
  const productItemsTableRef = useRef<ProductItemsTablePublicAPI>(null);
  const [productItemsTableSelectionModel, setProductItemsTableSelectionModel] =
    useState<GridRowSelectionModel>();
  const [localVariations, setLocalVariations] = useState<VariationItem[]>(
    variations || []
  );
  const [selectedTabId, setSelectedTabId] = useState<string | null>(
    CommonTabs.All
  );

  const tabs = useMemo(() => {
    const localTabs: TabWithCount[] = variations.length
      ? [
          {
            id: CommonTabs.All,
            label: t("common.labels.all"),
            count: variations.length,
          },
        ]
      : [];

    Object.values(VariationStatus).forEach((statusOption) => {
      if (statusOption === VariationStatus.None) {
        return;
      }

      localTabs.push({
        id: statusOption,
        label: t(
          `Projects.Variations.status.${
            statusOption === VariationStatus.Rejected ? "Reduced" : statusOption
          }`
        ),
        count:
          variations.filter((variation) => variation.status === statusOption)
            .length || 0,
      });
    });

    return localTabs;
  }, [variations, t]);

  const handleExportToExcel = () => {
    productItemsTableRef.current?.exportToExcel();
  };

  const handleClearSelection = () => {
    productItemsTableRef.current?.clearSelection();
  };

  const handleSelectedTabChange = useCallback(
    (newSelectedTabId: string | null) => {
      if (newSelectedTabId === CommonTabs.All) {
        setLocalVariations(variations);
      } else {
        setLocalVariations(
          variations.filter(
            (variation) => variation.status === newSelectedTabId
          )
        );
      }
      setSelectedTabId(newSelectedTabId);
    },
    [variations]
  );

  useEffect(() => {
    handleSelectedTabChange(selectedTabId);
  }, [handleSelectedTabChange, selectedTabId]);

  return (
    <Box height="100%" width="100%" display="flex" flexDirection="column">
      <StyledProductItemsHeader
        tabs={tabs}
        selectedItemsCount={productItemsTableSelectionModel?.length ?? 0}
        onSelectedTabChange={handleSelectedTabChange}
        onExportToExcel={handleExportToExcel}
        onClearSelection={handleClearSelection}
      />
      <Box sx={{ flex: 1, width: "100%" }} mt={3}>
        <VariationsTable
          selectionModel={productItemsTableSelectionModel}
          variations={localVariations}
          loading={loading}
          apiRef={productItemsTableRef}
          onSelectionModelChange={setProductItemsTableSelectionModel}
        />
      </Box>
    </Box>
  );
};
