import { Box } from "@mui/material";
import {
  GridCellParams,
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import {
  CompanyStatus,
  ChangeCompanyStatusMutation,
  ChangeCompanyStatusMutationVariables,
  Company,
} from "generated/graphql";
import { useCallback, useState } from "react";
import { useColumns } from "./Companies.constants";
import { GridHeader } from "../../../../components/GridHeader";
import { changeCompanyStatusMutation } from "./Companies.query";
import { Outlet, useNavigate } from "react-router-dom";
import { NewAppPaths } from "../../../../helpers/paths/paths";
import { exportToExcel } from "helpers/exportToExcel";
import { useActiveRemovedSuspendedStatusOptions } from "../../../../components/StatusTag/useActiveRemovedSuspendedOptions";
import { StyledDataGrid } from "components/StyledDataGrid";
import { useTranslation } from "react-i18next";
import * as Excel from "exceljs";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import { useGraphMutation } from "hooks/useGraphMutation";
import { StatusOption } from "components/StatusTag/StatusTag";
import { useCompanies } from "hooks/useCompanies";
import { getUserName, openLocallyOrInNewTab } from "helpers/miscelaneous";

export const Companies = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const companyStatusOptions =
    useActiveRemovedSuspendedStatusOptions() as StatusOption<CompanyStatus>[];
  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();

  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [sortingModel, setSortingModel] = useState<GridSortModel>([
    { field: "tradingName", sort: "asc" },
  ]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: "status",
        operator: "isAnyOf",
        value: [CompanyStatus.Active, CompanyStatus.Suspended],
      },
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const {
    companies,
    loading: companiesLoading,
    refetch: refetchCompanies,
  } = useCompanies();

  const [changeCompanyStatus, { loading: changeCompanyStatusLoading }] =
    useGraphMutation<
      ChangeCompanyStatusMutation,
      ChangeCompanyStatusMutationVariables
    >(
      changeCompanyStatusMutation,
      {
        update: () => {
          refetchCompanies();
        },
      },
      t("common.successMessages.entityUpdated", {
        entity: t("common.labels.company"),
      })
    );

  const handleExcelExport = async () => {
    if (!companies || !companies.length || !selectionModel?.length) {
      return;
    }

    const columns: Partial<Excel.Column>[] = [
      {
        header: t("AdminConsole.Companies.labels.tradingName"),
        key: "tradingName",
        width: 20,
      },
      { header: t("common.labels.status"), key: "status", width: 20 },
      {
        header: t("AdminConsole.Companies.labels.city"),
        key: "city",
        width: 20,
      },
      { header: t("common.labels.country"), key: "country", width: 20 },
      { header: t("common.labels.dateCreated"), key: "dateCreated", width: 20 },
      { header: t("common.labels.createdBy"), key: "creator", width: 20 },
    ];
    const rows = companies
      .filter((company) => (selectionModel || []).indexOf(company.id) >= 0)
      .map((company) => ({
        ...company,
        city: company.physicalAddress?.city ?? "",
        country: company.physicalAddress?.country ?? "",
        dateCreated: company ? new Date(company.dateCreated) : "",
        creator: getUserName(company.creator),
      }));

    exportToExcel(t("AdminConsole.Companies.labels.companies"), columns, rows);
  };
  const handleCreateNewCompany = () => {
    navigate(
      NewAppPaths.authorized.AdminConsole.children.Companies.children.NewCompany
    );
  };

  const handleGridRowSelectionModelChange = useCallback(
    (selectionModel: GridRowSelectionModel) => {
      setSelectionModel(selectionModel);
    },
    []
  );

  const handleCompanyStatusChange = useCallback(
    async (rowData: Company, newCompanyStatus: CompanyStatus) => {
      await changeCompanyStatus({
        variables: {
          id: rowData.id,
          status: newCompanyStatus,
        },
      });
    },
    [changeCompanyStatus]
  );

  const handleRowClick: GridEventListener<"rowClick"> = useCallback(
    (clickedRowData: GridRowParams<Company>, event) => {
      const selection = window.getSelection()?.toString();

      if (!selection) {
        const url =
          NewAppPaths.authorized.AdminConsole.children.Companies.children.CompanyDetails.pathConstructor(
            clickedRowData.row.id
          );
        openLocallyOrInNewTab(url, event, navigate);
      }
    },
    [navigate]
  );

  const columns = useColumns(companyStatusOptions, handleCompanyStatusChange);

  return (
    <>
      {/* TODO is this really used? */}
      <Outlet />
      <Box display="flex" flexDirection="column" alignItems="center" flex="1">
        <GridHeader
          title={t("AdminConsole.Companies.labels.companies")}
          actionButtonCaption={t("AdminConsole.Companies.labels.newCompany")}
          visibleRowsCount={visibleRowsCount || 0}
          selectedCount={selectionModel?.length || 0}
          onExportToExcel={handleExcelExport}
          onActionButtonClick={handleCreateNewCompany}
          sticky
        />
        <Box sx={{ flex: 1, width: "100%" }}>
          <StyledDataGrid
            apiRef={gridApiRef}
            rows={companies}
            columns={columns}
            getRowId={(rowData: Company) => rowData.id}
            onRowSelectionModelChange={handleGridRowSelectionModelChange}
            loading={companiesLoading || changeCompanyStatusLoading}
            sortingMode="client"
            sortModel={sortingModel}
            onSortModelChange={setSortingModel}
            filterMode="client"
            filterModel={filterModel}
            onFilterModelChange={setFilterModel}
            onRowClick={handleRowClick}
            getCellClassName={(params: GridCellParams<any, Company, any>) => {
              return params.row.status === CompanyStatus.Removed
                ? "greyed-out"
                : "";
            }}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box>
      </Box>
    </>
  );
};
