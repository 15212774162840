import { Box } from "@mui/material";
import {
  GridCellParams,
  GridEventListener,
  GridFilterModel,
  GridLogicOperator,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import { GridHeader } from "components/GridHeader";
import { StatusOption } from "components/StatusTag/StatusTag";
import { useActiveRemovedStatusOptions } from "components/StatusTag/useActiveRemovedStatusOptions";
import { StyledDataGrid } from "components/StyledDataGrid";
import {
  ChangeLookupCollectionStatusMutation,
  ChangeLookupCollectionStatusMutationVariables,
  LookupCollection,
  LookupCollectionStatus,
} from "generated/graphql";
import { changeLookupCollectionStatusMutation } from "graphql/mutations/changeLookupCollectionStatus";
import { exportToExcel } from "helpers/exportToExcel";
import { NewAppPaths } from "helpers/paths/paths";
import { useDataGridVisibleRows } from "hooks/useDataGridVisibleRows";
import { useGraphMutation } from "hooks/useGraphMutation";
import { useSimpleLookups } from "hooks/useLookups";
import { useSnackbar } from "notistack";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DuplicateLookupCollectionModal } from "./DuplicateLookupModal/DuplicateLookupModal";
import { useDuplicateLookupModal } from "./DuplicateLookupModal/useDuplicateLookupModal";
import { useColumns } from "./SimpleLookups.constants";
import { getUserName, openLocallyOrInNewTab } from "helpers/miscelaneous";

export const SimpleLookups = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const statusOptions =
    useActiveRemovedStatusOptions() as StatusOption<LookupCollectionStatus>[];
  const collectionToDuplicate = useRef<LookupCollection>();
  const {
    modalVisible,
    loading: duplicateLoading,
    setModalVisible: setDuplicateLookupCollectionModalVisibility,
    handleConfirm: handleDuplicateConfirm,
    handleCancel: handleDuplicateCancel,
    handleCloseModal: handleDuplicateClose,
  } = useDuplicateLookupModal();

  const { visibleRowsCount, gridApiRef } = useDataGridVisibleRows();
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>();
  const [sortingModel, setSortingModel] = useState<GridSortModel>([
    { field: "name", sort: "asc" },
  ]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: "status",
        operator: "isAnyOf",
        value: [LookupCollectionStatus.Active],
      },
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: [],
  });

  const {
    simpleLookupCollections,
    loading: simpleLookupCollectionsLoading,
    refetchSimpleLookupCollections,
  } = useSimpleLookups();

  const [
    changeLookupCollectionStatus,
    { loading: changeLookupCollectionStatusLoading },
  ] = useGraphMutation<
    ChangeLookupCollectionStatusMutation,
    ChangeLookupCollectionStatusMutationVariables
  >(
    changeLookupCollectionStatusMutation,
    {
      update: () => {
        refetchSimpleLookupCollections();
      },
    },
    t("common.successMessages.entityUpdated", {
      entity: t("AdminConsole.Lookups.lookupCollection"),
    })
  );

  const handleExcelExport = () => {
    const columns = [
      { header: t("common.labels.name"), key: "name", width: 20 },
      {
        header: t("AdminConsole.Lookups.optionsLockedForUsers"),
        key: "optionsLockedForUsers",
        width: 20,
      },
      {
        header: t("AdminConsole.Lookups.optionsCharLengthLimit"),
        key: "optionsCharLengthLimit",
        width: 20,
      },
      { header: t("common.labels.status"), key: "status", width: 20 },
      { header: t("common.labels.dateCreated"), key: "created", width: 20 },
      { header: t("common.labels.createdBy"), key: "creator", width: 20 },
    ];
    const rows =
      simpleLookupCollections
        .filter(
          (lookupCollection) =>
            (selectionModel || []).indexOf(lookupCollection.id) >= 0
        )
        .map((lookupCollection) => ({
          ...lookupCollection,
          creator: getUserName(lookupCollection.creator),
          created: lookupCollection
            ? new Date(lookupCollection.dateCreated)
            : "",
        })) || [];

    exportToExcel(t("AdminConsole.Lookups.lookupCollections"), columns, rows);
  };

  const handleCreateNewLookupCollection = () => {
    navigate(
      NewAppPaths.authorized.AdminConsole.children.System.children
        .NewLookupCollection
    );
  };

  const handleDuplicateLookupCollection = async (newCollectionName: string) => {
    if (!collectionToDuplicate.current) {
      // something went wrong, there's no collection to duplicate, however the duplicate modal is open
      return;
    }

    const response = await handleDuplicateConfirm(
      collectionToDuplicate.current!.id,
      {
        name: newCollectionName,
        optionsCharLengthLimit:
          collectionToDuplicate.current!.optionsCharLengthLimit,
        optionsLockedForUsers:
          collectionToDuplicate.current!.optionsLockedForUsers,
      }
    );

    if (response) {
      refetchSimpleLookupCollections();
      setDuplicateLookupCollectionModalVisibility(false);
    } else {
      enqueueSnackbar(t("common.errorMessages.unknownErrorOccured"), {
        variant: "error",
      });
    }
  };

  const triggerDuplicateCollectionFlow = useCallback(
    async (lookupCollection: LookupCollection) => {
      collectionToDuplicate.current = lookupCollection;
      setDuplicateLookupCollectionModalVisibility(true);
    },
    [setDuplicateLookupCollectionModalVisibility]
  );

  const handleRowClick: GridEventListener<"rowClick"> = useCallback(
    (clickedRowData: GridRowParams<LookupCollection>, event) => {
      const selection = window.getSelection()?.toString();
      if (!selection) {
        const url =
          NewAppPaths.authorized.AdminConsole.children.System.children.LookupCollectionDetails.pathConstructor(
            clickedRowData.row.id
          );
        openLocallyOrInNewTab(url, event, navigate);
      }
    },
    [navigate]
  );

  const handleLookupCollectionStatusChange = useCallback(
    async (row: LookupCollection, newStatus: LookupCollectionStatus) => {
      await changeLookupCollectionStatus({
        variables: {
          id: row.id,
          status: newStatus,
        },
      });
    },
    [changeLookupCollectionStatus]
  );

  const columns = useColumns(
    statusOptions,
    handleLookupCollectionStatusChange,
    triggerDuplicateCollectionFlow
  );

  return (
    <Box display="flex" flexDirection="column" alignItems="center" flex="1">
      <DuplicateLookupCollectionModal
        open={modalVisible}
        collectionToDuplicateName={collectionToDuplicate.current?.name}
        primaryBtnLoading={duplicateLoading}
        onClose={handleDuplicateClose}
        onPrimaryClick={handleDuplicateLookupCollection}
        onSecondaryClick={handleDuplicateCancel}
      />
      <GridHeader
        title={t("AdminConsole.Lookups.lookupCollections")}
        actionButtonCaption={t("common.buttons.addEntity", {
          entity: t("AdminConsole.Lookups.lookupCollection").toLowerCase(),
        })}
        visibleRowsCount={visibleRowsCount || 0}
        selectedCount={selectionModel?.length || 0}
        onExportToExcel={handleExcelExport}
        onActionButtonClick={handleCreateNewLookupCollection}
        sticky
      />
      <Box sx={{ flex: 1, width: "100%" }}>
        <StyledDataGrid
          apiRef={gridApiRef}
          rows={simpleLookupCollections}
          columns={columns}
          getRowId={(rowData: LookupCollection) => rowData.id}
          onRowSelectionModelChange={setSelectionModel}
          loading={
            simpleLookupCollectionsLoading ||
            changeLookupCollectionStatusLoading
          }
          sortingMode="client"
          sortModel={sortingModel}
          onSortModelChange={setSortingModel}
          filterMode="client"
          filterModel={filterModel}
          onFilterModelChange={setFilterModel}
          onRowClick={handleRowClick}
          getCellClassName={(
            params: GridCellParams<any, LookupCollection, any>
          ) => {
            return params.row.status === LookupCollectionStatus.Removed
              ? "greyed-out"
              : "";
          }}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
    </Box>
  );
};
