import {
  Autocomplete,
  FormControl,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { Overlay } from "components/Overlay";
import {
  AddContractInput,
  Contract,
  ContractType,
  EditContractInput,
} from "generated/graphql";
import { getCountryByName } from "helpers/countries/countries";
import { currencies } from "helpers/countries/countries.currencies";
import { validateData } from "helpers/validators";
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  getContractDataValidators,
  defaultContract,
} from "./ContractForm.constants";
import moment from "moment";
import "moment-timezone";
import { FormPublicApi } from "decl";
import {
  getTimezoneNamesWithOffset,
  stripTimezoneOffset,
} from "helpers/timezones";
import { dateISOFormat } from "../../../../../../constants";
import { FormattedNumericInput } from "containers/Projects/components/SchemaInterpretor/Field/Effect/components/FormattedNumericInput";
import { DatePicker } from "@mui/x-date-pickers";
import { CountryData } from "helpers/countries/countries.decl";
import { CountriesSelectNew } from "components/CountriesSelectNew";

export type ContractFormProps = {
  contractTypes: ContractType[];
  contract?: Contract;
  disabled?: boolean;
  apiRef?: React.Ref<FormPublicApi>;
  projectId: string;
  onChange: (updatedContract: AddContractInput | EditContractInput) => void;
};

export const ContractForm: React.FC<ContractFormProps> = ({
  disabled,
  contractTypes,
  contract,
  apiRef,
  projectId,
  onChange,
}) => {
  const { t } = useTranslation();
  // const today = useMemo(() => moment(new Date()).format(dateISOFormat), []);
  const timezoneNames = useMemo(() => getTimezoneNamesWithOffset(), []);

  const [selectedCountry, setSelectedCountry] = useState<CountryData>();
  const [formData, setFormData] = useState<AddContractInput>(
    contract ?? defaultContract
  );
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});

  const contractDataValidators = useMemo(
    () => getContractDataValidators(!!formData.value),
    [formData.value]
  );

  const handleTextFieldChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (evt) => {
    setFormData((curData) => ({
      ...curData,
      [evt.target.name]: evt.target.value,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { [evt.target.name]: _, ...rest } = curFormDataErrs;

      if (evt.target.name === "value") {
        const { valueCurrency: __, ...remainingFields } = rest;
        return remainingFields;
      }

      return rest;
    });
  };

  const validateForm = useCallback(
    (formData: AddContractInput | Contract) => {
      const validationResult = validateData(formData, contractDataValidators);

      if (validationResult.valid) {
        setFormDataErrors({});
        return true;
      }
      setFormDataErrors(validationResult.errors);

      return false;
    },
    [contractDataValidators]
  );

  const handleContractTypeChange = (
    event: SelectChangeEvent<string | null>
  ) => {
    setFormData((curData) => ({
      ...curData,
      contractTypeId: event.target.value!,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { contractTypeId: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const handleCurrencyChange = (
    _: SyntheticEvent<Element, Event>,
    value: string | null
  ) => {
    setFormData((curData) => ({
      ...curData,
      valueCurrency: value!,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { valueCurrency: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const handleStartDateChange = (value: Date | null) => {
    setFormData((curData) => ({
      ...curData,
      startDate: value,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { startDate: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const handleEndDateChange = (value: Date | null) => {
    setFormData((curData) => ({
      ...curData,
      endDate: value,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { endDate: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const handleTimezoneChange = (
    _: SyntheticEvent<Element, Event>,
    value: string | null
  ) => {
    setFormData((curData) => ({
      ...curData,
      timeZone: value!,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { timeZone: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const handleCountryChange = (newCountry: CountryData) => {
    setSelectedCountry(newCountry);
    setFormData((curFormData) => ({
      ...curFormData,
      country: newCountry.name,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { country: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const resetForm = useCallback(() => {
    setFormData(contract ?? defaultContract);
  }, [contract]);

  useEffect(() => {
    const updatedFormData: AddContractInput | EditContractInput = {
      contractTypeId: formData.contractTypeId,
      coordinatesLatitude: formData.coordinatesLatitude,
      coordinatesLongitude: formData.coordinatesLongitude,
      country: formData.country,
      friendlyName: formData.friendlyName,
      name: formData.name,
      number: formData.number,
      province: formData.province,
      ...(formData.startDate
        ? { startDate: moment(formData.startDate).format(dateISOFormat) }
        : {}),
      ...(formData.endDate
        ? { endDate: moment(formData.endDate).format(dateISOFormat) }
        : {}),
      timeZone: stripTimezoneOffset(formData.timeZone || ""),
      value: formData.value,
      valueCurrency: formData.valueCurrency,
      projectId,
      id: contract?.id,
    };

    onChange?.(updatedFormData);
  }, [formData, onChange, projectId, contract]);

  useEffect(() => {
    if (contract) {
      setFormData({
        ...contract,
        timeZone:
          timezoneNames.find((timezone) =>
            timezone.includes(contract.timeZone)
          ) || "",
      });
    } else {
      setFormData(defaultContract);
    }
    if (contract) {
      setSelectedCountry(getCountryByName(contract!.country));
    }
  }, [contract, timezoneNames]);

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => validateForm(formData),
      reset: resetForm,
    }),
    [validateForm, formData, resetForm]
  );

  return (
    <>
      {disabled && <Overlay withBackground />}
      <Grid container spacing={6}>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            name="name"
            value={formData.name}
            onChange={handleTextFieldChange}
            type="text"
            label={t("common.labels.name")}
            variant="standard"
            error={!!formDataErrors.name}
            helperText={formDataErrors.name}
            InputLabelProps={{ shrink: true }}
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            name="friendlyName"
            value={formData.friendlyName}
            onChange={handleTextFieldChange}
            type="text"
            label={t("common.labels.friendlyName")}
            variant="standard"
            error={!!formDataErrors.friendlyName}
            helperText={formDataErrors.friendlyName}
            InputLabelProps={{ shrink: true }}
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            name="number"
            value={formData.number}
            onChange={handleTextFieldChange}
            type="text"
            label={t("common.labels.number")}
            variant="standard"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
            <>
              <InputLabel id="contract-type-select-label" shrink>
                <Typography
                  color={formDataErrors.contractTypeId ? "error" : "inherit"}
                >{`${t("common.labels.type")} *`}</Typography>
              </InputLabel>
              <Select
                labelId="contract-type-select-label"
                id="contract-type-select"
                value={formData.contractTypeId}
                onChange={handleContractTypeChange}
                error={!!formDataErrors.contractTypeId}
                label={t("common.labels.type")}
                disabled={!!contract}
              >
                {contractTypes.map((ct) => (
                  <MenuItem key={ct.id} value={ct.id}>
                    {`${ct.description} ${ct.version ?? ""} ${
                      ct.subType ?? ""
                    }`}
                  </MenuItem>
                ))}
              </Select>
              {!!formDataErrors.contractTypeId && (
                <Typography variant="caption" color="error" mt={0.5}>
                  {formDataErrors.contractTypeId}
                </Typography>
              )}
            </>
          </FormControl>
        </Grid>
        <Grid item md={3} xs={6}>
          <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
            <>
              <InputLabel id="currency-select-label" shrink>
                {t("common.labels.currency")}
              </InputLabel>
              <Autocomplete
                disablePortal
                id="currency-autcomplete"
                options={currencies}
                sx={{ maxHeight: 200 }}
                value={formData.valueCurrency ?? ""}
                onChange={handleCurrencyChange}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={t("common.labels.currency")}
                    required
                    error={!!formDataErrors.valueCurrency}
                    helperText={formDataErrors.valueCurrency}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                )}
              />
            </>
          </FormControl>
        </Grid>
        <Grid item md={3} xs={6}>
          <TextField
            fullWidth
            name="value"
            type="text"
            variant="standard"
            size="small"
            value={formData.value ?? ""}
            onChange={handleTextFieldChange}
            data-testid="value-textfield"
            label={t("common.labels.value")}
            error={!!formDataErrors.value}
            helperText={formDataErrors.value}
            required
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: FormattedNumericInput as any,
            }}
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <DatePicker
            value={formData.startDate ? new Date(formData.startDate) : null}
            format="yyyy-MM-dd"
            onChange={handleStartDateChange}
            label={t("common.labels.startDate")}
            slotProps={{
              popper: {
                placement: "bottom-end",
              },
            }}
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <DatePicker
            value={formData.endDate ? new Date(formData.endDate) : null}
            onChange={handleEndDateChange}
            label={t("AdminConsole.Contracts.labels.endDate")}
            format="yyyy-MM-dd"
            // minDate={
            //   formData.startDate
            //     ? moment
            //         .max(moment(formData.startDate), moment(new Date()))
            //         .format(dateISOFormat)
            // : today
            // }
            slotProps={{
              popper: {
                placement: "bottom-end",
              },
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            name="province"
            value={formData.province}
            onChange={handleTextFieldChange}
            type="text"
            label={t("AdminConsole.Contracts.labels.province")}
            variant="standard"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <FormLabel id="country-select">
              <Typography
                variant="caption"
                color={formDataErrors.country ? "error" : "inherit"}
              >
                {`${t("common.labels.country")} *`}
              </Typography>
            </FormLabel>
            <CountriesSelectNew
              value={selectedCountry}
              onChange={handleCountryChange}
              hasErrors={!!formDataErrors.country}
              adminConsoleStyle
            />
            {!!formDataErrors.country && (
              <Typography variant="caption" color="error">
                {formDataErrors.country}
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
            <Autocomplete
              disablePortal
              id="time-zone-autocomplete"
              options={timezoneNames}
              sx={{ maxHeight: 200 }}
              value={formData.timeZone ?? ""}
              onChange={handleTimezoneChange}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={t("common.labels.timezone")}
                  required
                  error={!!formDataErrors.timeZone}
                  helperText={formDataErrors.timeZone}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={3} xs={6}>
          <TextField
            fullWidth
            name="coordinatesLatitude"
            value={formData.coordinatesLatitude ?? ""}
            onChange={handleTextFieldChange}
            type="text"
            label={t("common.labels.siteLatitude")}
            placeholder="E.g. -26.056271"
            error={!!formDataErrors.coordinatesLatitude}
            helperText={formDataErrors.coordinatesLatitude}
            variant="standard"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item md={3} xs={6}>
          <TextField
            fullWidth
            name="coordinatesLongitude"
            value={formData.coordinatesLongitude ?? ""}
            onChange={handleTextFieldChange}
            type="text"
            label={t("common.labels.siteLongitude")}
            placeholder="E.g. 28.069359"
            error={!!formDataErrors.coordinatesLongitude}
            helperText={formDataErrors.coordinatesLongitude}
            variant="standard"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
    </>
  );
};
